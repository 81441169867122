import React from 'react';
import {SmallFooter} from '@nib-components/footer';
import {useBrandSpecificLinksStatic} from '../../../hooks/UseBrandSpecificLinksStatic';
import {isProd} from '../../../services/sharedUtils';

export const CorporateFooter = () => {
  const brandSpecificLinks = useBrandSpecificLinksStatic();
  const config = {
    id: 'small-footer-corporate',
    copyrightText: `Copyright © ${new Date().getFullYear()} nib health funds limited ABN 83 000 124 381`,
    phoneNumber: brandSpecificLinks.contactNumber,
    links: [
      {
        id: 'ga-footer-ing-nib-privacy',
        title: 'Privacy Policy',
        targetBlank: true,
        url: isProd() ? 'https://www.nib.com.au/legal/privacy-policy' : 'https://www.nib-cf-test.com/legal/privacy-policy'
      }
    ]
  };
  return <SmallFooter config={config} />;
};
