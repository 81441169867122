import React from 'react';
import HeaderFooterLayout from '@nib-components/header-footer-layout';
import {Header} from '../../Header';
import {Footer} from '../../Footer';

const CorporateHeader = () => <Header variant="corporate" />;
const CorporateFooter = () => <Footer variant="corporate" />;

const HeaderFooterCorporate = ({children}) => (
  <HeaderFooterLayout variation="arhi" header={CorporateHeader} footer={CorporateFooter}>
    {children}
  </HeaderFooterLayout>
);

export default HeaderFooterCorporate;
