/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';

type NullableJSXElement = JSX.Element | null;

type ExtractPropType<T> = T extends {props: React.ComponentType<infer A>} ? A : never;
type ExtractProps<V, T> = V extends {variant: T} ? ExtractPropType<V> : never;

type PropTypeMap<T extends {[key: string]: {}}> = {[K in keyof T]: {variant: K; props: T[K]}};
type PropTypes<T extends {[key: string]: {}}> = PropTypeMap<T>[keyof PropTypeMap<T>];

type Func = <V extends {[key: string]: React.FunctionComponent<any>}>(
  variants: V,
  defaultVariant?: React.FunctionComponent<any>
) => <T extends PropTypes<V>['variant']>(props: ExtractProps<PropTypes<V>, T> & {variant: T}) => NullableJSXElement;

// Take in variants (or a default) object containing components and returns the correct variant / functional
export const withVariants: Func =
  (variants, DefaultVariant) =>
  (props): any => {
    const {variant, ...rest} = props as any;
    const variantAsString = variant as unknown as string;
    const Component = variants[variantAsString] as any;

    if (Component) {
      return <Component {...rest} />;
    }
    if (DefaultVariant) {
      return <DefaultVariant {...rest} />;
    }
    return null;
  };

type GetProps<T> = T extends {
  variant: infer A;
  props: React.FunctionComponent<infer B>;
}
  ? B & {variant: A}
  : never;
type Distribute<U> = U extends any ? GetProps<U> : never;
export type Props<V extends {[key: string]: React.FunctionComponent<any>}> = Distribute<PropTypes<V>>;
