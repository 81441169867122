import React from 'react';
import Loader from '@nib/loader';
import styled from 'styled-components';
import {colorLighter} from '@nib-components/theme';

interface LoadingProps {
  center?: boolean;
  size?: string;
}

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: ${(props: LoadingProps) => (props.center ? 'center' : 'flex-start')};
`;

const Loading: React.FunctionComponent<LoadingProps> = (props) => (
  <LoadingWrapper center={props.center}>
    <Loader fill={colorLighter} size={props.size} />
  </LoadingWrapper>
);

export default Loading;
