import React from 'react';
import {SmallFooter} from '@nib-components/footer';
import {Box} from '@nib/layout';
import Link from '@nib-components/link';
import {useBrandSpecificLinksStatic} from '../../../hooks/UseBrandSpecificLinksStatic';

const CopyrightSection = () => (
  <Box marginVertical={2} maxWidth="60rem">
    AAMI Health Insurance is issued by nib health funds limited ABN 83 000 124 381 (nib), a registered private health insurer, and is marketed by Platform CoVentures Pty Ltd ABN 82 626 829 623 (PC), a
    Suncorp Group company. PC is an authorised agent of nib and receives commission from nib. Read the{' '}
    <Link
      component="a"
      target="_blank"
      rel="noopener noreferrer"
      id="ga-footer-aami-privacy-booklet"
      href="https://www.aami.com.au/aami/documents/personal/health/policy-booklet-health-insurance-01-07-2021.pdf"
    >
      policy booklet
    </Link>{' '}
    before buying this insurance.
  </Box>
);

export const AAMIFooter = () => {
  const brandSpecificLinks = useBrandSpecificLinksStatic();
  const config = {
    id: 'small-footer-aami',
    copyrightText: <CopyrightSection />,
    phoneNumber: brandSpecificLinks.contactNumber,
    links: [
      {
        id: 'ga-footer-fund-rules',
        title: 'Fund Rules',
        url: 'https://www.nib.com.au/docs/fund-rules',
        targetBlank: true
      },
      {
        id: 'ga-footer-aami-privacy',
        title: 'AAMI privacy policy',
        targetBlank: true,
        url: brandSpecificLinks.privacyPolicyExternal || ''
      },
      {
        id: 'ga-footer-aami-nib-privacy',
        title: 'nib privacy policy',
        targetBlank: true,
        url: 'https://www.nib.com.au/docs/privacy-policy'
      }
    ]
  };
  return <SmallFooter config={config} />;
};
