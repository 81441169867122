import React from 'react';
import HeaderFooterLayout from '@nib-components/header-footer-layout';
import {Header} from '../../Header';
import {Footer} from '../../Footer';

const ApiaHeader = () => <Header variant="apia" />;
const ApiaFooter = () => <Footer variant="apia" />;

const HeaderFooterApia = ({children}) => (
  <HeaderFooterLayout variation="arhi" header={ApiaHeader} footer={ApiaFooter}>
    {children}
  </HeaderFooterLayout>
);

export default HeaderFooterApia;
