import React from 'react';
import HeaderFooterLayout from '@nib-components/header-footer-layout';
import {SmallFooter} from '@nib-components/footer';
import {Header} from '../../Header';

const NibHeader = () => <Header variant="nib" />;

const HeaderFooterNib = ({children}) => {
  return (
    <HeaderFooterLayout variation="arhi" header={NibHeader} footer={SmallFooter}>
      {children}
    </HeaderFooterLayout>
  );
};

export default HeaderFooterNib;
