import React from 'react';
import {SmallFooter} from '@nib-components/footer';
import {Box} from '@nib/layout';
import {useBrandSpecificLinksStatic} from '../../../hooks/UseBrandSpecificLinksStatic';

const CopyrightSection = () => (
  <Box marginVertical={2} maxWidth="60rem">
    Seniors Health Insurance is issued by nib Health Funds Limited ABN 83 000 124 381 (nib), a registered private health insurer, and is promoted by Greenstone Financial Services Pty Ltd (GFS) ABN 53
    128 692 884, trading as Australian Seniors Insurance Agency (Australian Seniors). Australian Seniors is an authorised agent of nib and receives commission from nib.
  </Box>
);

export const SeniorsFooter = () => {
  const brandSpecificLinks = useBrandSpecificLinksStatic();

  const config = {
    id: 'small-footer-seniors',
    copyrightText: <CopyrightSection />,
    phoneNumber: brandSpecificLinks.contactNumber,
    links: [
      {
        id: 'ga-footer-fund-rules',
        title: 'Fund Rules',
        url: 'https://www.nib.com.au/docs/fund-rules',
        targetBlank: true
      },
      {
        id: 'ga-footer-seniors-privacy',
        title: 'Australian Seniors privacy policy',
        targetBlank: true,
        url: brandSpecificLinks.privacyPolicyExternal || ''
      },
      {
        id: 'ga-footer-seniors-nib-privacy',
        title: 'nib privacy policy',
        targetBlank: true,
        url: 'https://www.nib.com.au/docs/privacy-policy'
      }
    ]
  };
  return <SmallFooter config={config} />;
};
