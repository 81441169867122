import React from 'react';
import HeaderFooterLayout from '@nib-components/header-footer-layout';
import {Header} from '../../Header';
import {Footer} from '../../Footer';

const SuncorpHeader = () => <Header variant="suncorp" />;
const SuncorpFooter = () => <Footer variant="suncorp" />;

const HeaderFooterSuncorp = ({children}) => (
  <HeaderFooterLayout variation="arhi" header={SuncorpHeader} footer={SuncorpFooter}>
    {children}
  </HeaderFooterLayout>
);

export default HeaderFooterSuncorp;
