import React from 'react';
import HeaderFooterLayout from '@nib-components/header-footer-layout';
import {Header} from '../../Header';
import {Footer} from '../../Footer';

const RealHeader = () => <Header variant="real" />;
const RealFooter = () => <Footer variant="real" />;

const HeaderFooterReal = ({children}) => (
  <HeaderFooterLayout variation="arhi" header={RealHeader} footer={RealFooter}>
    {children}
  </HeaderFooterLayout>
);

export default HeaderFooterReal;
