import React from 'react';
import {Box} from '@nib/layout';

const AgentBanner = ({groupName, showGenericWelcomeMessage}) => (
  <Box background="white" padding={4} textAlign="center">
    {showGenericWelcomeMessage ? (
      <>Welcome, continue below to get your health insurance quote.</>
    ) : (
      <>
        Welcome from <b>{groupName}</b>, continue below to get your health insurance quote.
      </>
    )}
  </Box>
);

export default AgentBanner;
