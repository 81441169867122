import React from 'react';
import HeaderFooterLayout from '@nib-components/header-footer-layout';
import {Header} from '../../Header';
import {Footer} from '../../Footer';

const IngHeader = () => <Header variant="ing" />;
const IngFooter = () => <Footer variant="ing" />;

const HeaderFooterIng = ({children}) => (
  <HeaderFooterLayout variation="arhi" header={IngHeader} footer={IngFooter}>
    {children}
  </HeaderFooterLayout>
);

export default HeaderFooterIng;
