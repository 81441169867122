import React from 'react';
import HeaderFooterLayout from '@nib-components/header-footer-layout';
import {Header} from '../../Header';
import {DentalPassFooter} from '../../Footer/DentalPass';

const HeaderDentalPass = () => <Header variant="nibP2PWeb" />;

const HeaderFooterDentalPass = ({children}) => {
  return (
    <HeaderFooterLayout variation="arhi" header={HeaderDentalPass} footer={DentalPassFooter}>
      {children}
    </HeaderFooterLayout>
  );
};

export default HeaderFooterDentalPass;
