import React from 'react';
import {SmallFooter} from '@nib-components/footer';
import {Box} from '@nib/layout';
import {useBrandSpecificLinksStatic} from '../../../hooks/UseBrandSpecificLinksStatic';

const CopyrightSection = () => (
  <Box marginVertical={2} maxWidth="60rem">
    GU Health is a business of nib health funds limited ABN 83 000 124 381. Copyright © nib health funds limited 2024.
  </Box>
);

export const GUFooter = () => {
  const brandSpecificLinks = useBrandSpecificLinksStatic();
  const config = {
    id: 'small-footer-gu',
    copyrightText: <CopyrightSection />,
    phoneNumber: brandSpecificLinks.contactNumber,
    links: [
      {
        id: 'ga-footer-fund-rules',
        title: 'Fund Rules',
        url: 'https://www.guhealth.com.au/docs/fund-rules',
        targetBlank: true
      },
      {
        id: 'ga-footer-terms-and-conditions',
        title: 'Terms & Conditions',
        url: 'https://www.guhealth.com.au/terms-and-conditions',
        targetBlank: true
      },
      {
        id: 'ga-footer-gu-privacy',
        title: 'Privacy Policy',
        targetBlank: true,
        url: 'https://www.guhealth.com.au/docs/gu-health-privacy-policy'
      },
      {
        id: 'ga-footer-gu-code-of-conduct',
        title: 'Code of Conduct',
        targetBlank: true,
        url: 'https://privatehealthcareaustralia.org.au/codeofconduct'
      }
    ]
  };
  return <SmallFooter config={config} />;
};
