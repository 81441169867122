import React from 'react';
import HeaderFooterLayout from '@nib-components/header-footer-layout';
import {Header} from '../../Header';
import {Footer} from '../../Footer';

const PricelineHeader = () => <Header variant="priceline" />;
const PricelineFooter = () => <Footer variant="priceline" />;

const HeaderFooterPriceline = ({children}) => (
  <HeaderFooterLayout variation="arhi" header={PricelineHeader} footer={PricelineFooter}>
    {children}
  </HeaderFooterLayout>
);

export default HeaderFooterPriceline;
