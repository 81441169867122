import React from 'react';
import HeaderFooterLayout from '@nib-components/header-footer-layout';
import {Props, withVariants} from '../WithVariants';
import HeaderFooterApia from './Apia';
import HeaderFooterNib from './nib';
import HeaderFooterIng from './Ing';
import HeaderFooterAami from './Aami';
import HeaderFooterSuncorp from './Suncorp';
import HeaderFooterPriceline from './Priceline';
import HeaderFooterSeniors from './Seniors';
import HeaderFooterReal from './Real';
import HeaderFooterDentalPass from './DentalPass';
import HeaderFooterCorporate from './Corporate';
import HeaderFooterGu from './Gu';

// Nib and Apia header footers are so different and I'd rather not default to either of them
// Using this as the default, which uses the default HeaderFooterLayout arhi variation from MESH
const DefaultHeaderFooter = ({children}) => {
  return (
    <HeaderFooterLayout variation="arhi" smallLayout={true}>
      {children}
    </HeaderFooterLayout>
  );
};

const variants = {
  nib: HeaderFooterNib,
  nibP2PWeb: HeaderFooterDentalPass,
  apia: HeaderFooterApia,
  ing: HeaderFooterIng,
  aami: HeaderFooterAami,
  suncorp: HeaderFooterSuncorp,
  priceline: HeaderFooterPriceline,
  seniors: HeaderFooterSeniors,
  real: HeaderFooterReal,
  corporate: HeaderFooterCorporate,
  guhealth: HeaderFooterGu
};

export const HeaderFooter = withVariants(variants, DefaultHeaderFooter);
export type HeaderFooter = Props<typeof variants>;
