import {useStaticQuery, graphql} from 'gatsby';

export type TealiumBusiness = {
  name: string;
  siteName: string;
  segment: string;
};

export type TealiumBusinessQuery = {
  brandContent: {
    tealiumBusiness: TealiumBusiness;
  };
};

export const useTealiumBusinessSegmentStatic = () => {
  const {brandContent} = useStaticQuery<TealiumBusinessQuery>(
    graphql`
      query TealiumBusinessQuery {
        brandContent {
          tealiumBusiness {
            name
            siteName
            segment
          }
        }
      }
    `
  );
  return brandContent.tealiumBusiness;
};
