import React from 'react';
import {SmallFooter} from '@nib-components/footer';
import {Box} from '@nib/layout';
import {useBrandSpecificLinksStatic} from '../../../hooks/UseBrandSpecificLinksStatic';
import Link from '@nib-components/link';
import styled from 'styled-components';
import {smallFooterLinkColor} from '@nib-components/theme';

const StyledLink = styled(Link)`
  color: ${smallFooterLinkColor};
`;

const CopyrightSection = () => (
  <Box marginVertical={2} maxWidth="60rem">
    Priceline Health Insurance is issued by nib health funds limited ABN 83 000 124 381 (nib), a registered private health insurer, and is marketed by Priceline Pty Limited (ABN 18 005 968 310).
    Priceline Pty Limited is an authorised agent of nib and receives commission from nib. Read the{' '}
    <StyledLink component="a" target="_blank" rel="noopener noreferrer" id="ga-footer-priceline-privacy-booklet" href="https://www.priceline.com.au/media/wysiwyg/Priceline_Policy_Booklet_1.pdf">
      policy booklet
    </StyledLink>{' '}
    before buying this insurance.
  </Box>
);

export const PricelineFooter = () => {
  const brandSpecificLinks = useBrandSpecificLinksStatic();

  const config = {
    id: 'small-footer-priceline',
    copyrightText: <CopyrightSection />,
    phoneNumber: brandSpecificLinks.contactNumber,
    links: [
      {
        id: 'ga-footer-fund-rules',
        title: 'Fund Rules',
        url: 'https://www.nib.com.au/docs/priceline-policy-booklet-nib',
        targetBlank: true
      },
      {
        id: 'ga-footer-priceline-privacy',
        title: 'Priceline privacy policy',
        targetBlank: true,
        url: brandSpecificLinks.privacyPolicyExternal || ''
      },
      {
        id: 'ga-footer-priceline-nib-privacy',
        title: 'nib privacy policy',
        targetBlank: true,
        url: 'https://www.nib.com.au/docs/privacy-policy'
      }
    ]
  };
  return <SmallFooter config={config} />;
};
