import React from 'react';
import {SmallFooter} from '@nib-components/footer';
import {Box} from '@nib/layout';
import {useBrandSpecificLinksStatic} from '../../../hooks/UseBrandSpecificLinksStatic';

const CopyrightSection = () => (
  <>
    <Box marginVertical={2} maxWidth="57rem">
      ING Health Insurance is issued by nib health funds limited ABN 83 000 124 381 (nib), a registered private health insurer, and is marketed by ING Bank (Australia) Limited (ABN 24 000 893 292)
      (ING). ING is an authorised agent of nib and receives commission from nib. An ING Health Insurance policy issued does not represent a deposit with or liability of, and is not guaranteed or
      otherwise supported by, ING or its related bodies corporate.
    </Box>
    <Box marginBottom={2}>The information contained in this website is correct as at the date of publication and is subject to change.</Box>
  </>
);

export const IngFooter = () => {
  const brandSpecificLinks = useBrandSpecificLinksStatic();

  const config = {
    id: 'small-footer-ing',
    copyrightText: <CopyrightSection />,
    phoneNumber: brandSpecificLinks.contactNumber,
    links: [
      {
        id: 'ga-footer-fund-rules',
        title: 'Fund Rules',
        url: 'https://www.nib.com.au/docs/fund-rules',
        targetBlank: true
      },
      {
        id: 'ga-footer-ing-privacy',
        title: 'ING privacy policy',
        targetBlank: true,
        url: brandSpecificLinks.privacyPolicyExternal || ''
      },
      {
        id: 'ga-footer-ing-nib-privacy',
        title: 'nib privacy policy',
        targetBlank: true,
        url: 'https://www.nib.com.au/docs/privacy-policy'
      }
    ]
  };

  return <SmallFooter config={config} />;
};
