import React from 'react';
import HeaderFooterLayout from '@nib-components/header-footer-layout';
import {Header} from '../../Header';
import {Footer} from '../../Footer';

const SeniorsHeader = () => <Header variant="seniors" />;
const SeniorsFooter = () => <Footer variant="seniors" />;

const HeaderFooterSeniors = ({children}) => (
  <HeaderFooterLayout variation="arhi" header={SeniorsHeader} footer={SeniorsFooter}>
    {children}
  </HeaderFooterLayout>
);

export default HeaderFooterSeniors;
