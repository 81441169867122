import type {HospitalProduct, ExtrasProduct} from '@nib/types-session-api';

import {ARHIJoinState} from '../../rootReducer';
import _get from 'lodash/get';

export const selectHospitalData = (state: ARHIJoinState): HospitalProduct => _get(state, 'session.session.productSelection.hospital') || ({} as HospitalProduct);
export const selectExtrasData = (state: ARHIJoinState): ExtrasProduct => _get(state, 'session.session.productSelection.extras') || ({} as ExtrasProduct);

export const getHospitalProductName = (state: ARHIJoinState) => selectHospitalData(state).name;
export const getHospitalProductShortName = (state: ARHIJoinState) => selectHospitalData(state).shortName;
export const getExtrasProductName = (state: ARHIJoinState) => selectExtrasData(state).name;
export const getExtrasProductShortName = (state: ARHIJoinState) => selectExtrasData(state).name;

export const getHospitalProductDisplayName = (state: ARHIJoinState) => {
  return getHospitalProductShortName(state) || getHospitalProductName(state);
};

export const getExtrasProductDisplayName = (state: ARHIJoinState) => {
  return getExtrasProductShortName(state) || getExtrasProductName(state);
};

export const getExcess = (state: ARHIJoinState) => {
  return selectHospitalData(state).excess || '';
};
