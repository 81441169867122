import React from 'react';
import {SmallFooter} from '@nib-components/footer';
import {useBrandSpecificLinksStatic} from '../../../hooks/UseBrandSpecificLinksStatic';
import {isProd} from '../../../services/sharedUtils';

export const DentalPassFooter = () => {
  const brandSpecificLinks = useBrandSpecificLinksStatic();

  const config = {
    id: 'small-footer-nib',
    copyrightText: `Copyright © ${new Date().getFullYear()} nib health funds limited ABN 83 000 124 381`,
    phoneNumber: brandSpecificLinks.contactNumber,
    links: [
      {
        id: 'ga-footer-fund-rules',
        title: 'Fund Rules',
        url: isProd() ? 'https://www.nib.com.au/docs/fund-rules' : 'https://www.nib-cf-test.com/docs/fund-rules',
        targetBlank: true
      },
      {
        id: 'ga-footer-nib-privacy',
        title: 'T&C',
        targetBlank: true,
        url: isProd() ? 'https://www.nib.com.au/docs/online-terms' : 'https://www.nib-cf-test.com/docs/online-terms'
      },
      {
        id: 'ga-footer-ing-nib-privacy',
        title: 'Privacy',
        targetBlank: true,
        url: isProd() ? 'https://www.nib.com.au/legal/privacy-policy' : 'https://www.nib-cf-test.com/legal/privacy-policy'
      },
      {
        id: 'ga-footer-code-conduct',
        title: 'Code of Conduct',
        targetBlank: true,
        url: isProd() ? 'https://www.nib.com.au/legal#codeofconduct' : 'https://www.nib-cf-test.com/legal#codeofconduct'
      }
    ]
  };

  return <SmallFooter config={config} />;
};
