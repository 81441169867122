import React from 'react';
import HeaderFooterLayout from '@nib-components/header-footer-layout';
import {Header} from '../../Header';
import {Footer} from '../../Footer';

const GuHeader = () => <Header variant="guhealth" />;
const GuFooter = () => <Footer variant="guhealth" />;

const HeaderFooterGu = ({children}) => (
  <HeaderFooterLayout variation="arhi" header={GuHeader} footer={GuFooter}>
    {children}
  </HeaderFooterLayout>
);

export default HeaderFooterGu;
