import React from 'react';
import {HeaderConfigType, SmallHeader} from '@nib-components/header';
import Divider from '@nib-components/divider';
import {colorBrandBase, colorDarker} from '@nib-components/theme';
import {Props, withVariants} from '../WithVariants';
import {
  APIA_HOMEPAGE_URL,
  ING_HOMEPAGE_URL,
  AAMI_HOMEPAGE_URL,
  SUNCORP_HOMEPAGE_URL,
  PRICELINE_HOMEPAGE_URL,
  SENIORS_HOMEPAGE_URL,
  REAL_HOMEPAGE_URL,
  NIB_HOMEPAGE_URL,
  CORPORATE_HOME,
  GUHEALTH_HOMEPAGE_URL
} from '../../constants';
import styled from 'styled-components';
import Logo from '@nib-components/logo';
import {useShallowEqualSelector} from '../../hooks';
import {getCompanyLogoUrl} from '../../redux/session/selectors';

const HeaderDivider = styled(Divider)`
  border-bottom: 5px solid ${colorBrandBase};
`;

const ApiaHeader = () => {
  return (
    <>
      <SmallHeader logoLink={APIA_HOMEPAGE_URL} />
      <HeaderDivider />
    </>
  );
};

const IngHeader = () => {
  return (
    <>
      <SmallHeader logoLink={ING_HOMEPAGE_URL} />
      <HeaderDivider />
    </>
  );
};

const AamiHeader = () => {
  return (
    <>
      <SmallHeader logoLink={AAMI_HOMEPAGE_URL} />
      <HeaderDivider />
    </>
  );
};

const SuncorpHeader = () => {
  return (
    <>
      <SmallHeader logoLink={SUNCORP_HOMEPAGE_URL} />
      <HeaderDivider />
    </>
  );
};

const PricelineHeader = () => {
  return (
    <>
      <SmallHeader logoLink={PRICELINE_HOMEPAGE_URL} />
      <HeaderDivider />
    </>
  );
};

const SeniorsHeader = () => {
  return (
    <>
      <SmallHeader logoLink={SENIORS_HOMEPAGE_URL} />
      <HeaderDivider />
    </>
  );
};

const GuHeader = () => {
  return (
    <>
      <SmallHeader logoLink={GUHEALTH_HOMEPAGE_URL} />
      <HeaderDivider />
    </>
  );
};

const RealHeader = () => {
  return (
    <>
      <SmallHeader logoLink={REAL_HOMEPAGE_URL} />
      <HeaderDivider />
    </>
  );
};

const CorporatePartnerLogo = styled.img`
  max-height: 24px;
  max-width: 150px;
  padding-right: 1em;
  border-right: 2px solid ${colorDarker};
`;

const NibLogo = styled(Logo)`
  padding-left: 1em;
`;

const getCorporateHeaderConfig = () => {
  const partnerLogoUrl = useShallowEqualSelector(getCompanyLogoUrl);
  return {
    id: 'corporate-header',
    logoUrl: CORPORATE_HOME,
    ribbon: [],
    navLinks: [],
    logoComponent: () => <CorporateLogoComponent logoUrl={partnerLogoUrl} />,
    smallHeaderConfig: {
      id: 'corporate-header',
      logoUrl: CORPORATE_HOME,
      navLinks: []
    }
  } as HeaderConfigType;
};

const CorporateLogoComponent = ({logoUrl}) => (
  <>
    {logoUrl && <CorporatePartnerLogo src={logoUrl} />}
    <NibLogo />
  </>
);

const CorporateHeader = () => {
  return (
    <>
      <SmallHeader logoLink={CORPORATE_HOME} config={getCorporateHeaderConfig()} />
      <HeaderDivider />
    </>
  );
};

const NibHeader = () => <SmallHeader invert={true} />;

const NibP2pWebHeader = () => {
  return <SmallHeader invert={true} withAbsoluteLinks absoluteBaseUrl={NIB_HOMEPAGE_URL} />;
};

const variants = {
  nib: NibHeader,
  apia: ApiaHeader,
  ing: IngHeader,
  aami: AamiHeader,
  suncorp: SuncorpHeader,
  priceline: PricelineHeader,
  seniors: SeniorsHeader,
  real: RealHeader,
  nibP2PWeb: NibP2pWebHeader,
  corporate: CorporateHeader,
  guhealth: GuHeader
};

export const Header = withVariants(variants, SmallHeader);
export type Header = Props<typeof variants>;

export default Header;
