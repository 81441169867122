import React from 'react';
import HeaderFooterLayout from '@nib-components/header-footer-layout';
import {Header} from '../../Header';
import {Footer} from '../../Footer';

const AamiHeader = () => <Header variant="aami" />;
const AamiFooter = () => <Footer variant="aami" />;

const HeaderFooterAami = ({children}) => (
  <HeaderFooterLayout variation="arhi" header={AamiHeader} footer={AamiFooter}>
    {children}
  </HeaderFooterLayout>
);

export default HeaderFooterAami;
