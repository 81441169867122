import React from 'react';
import {enableES5} from 'immer';
import {colorSunsetPink20, colorLightest, copyFontFamily} from '@nib-components/theme';
import styled from 'styled-components';
import SEO, {SeoProps} from '../SEO';
import PageTheme from '../PageTheme';
import {HeaderFooter} from '../HeaderFooter';
import {getBrand, isCorporateBrand, isNibThemeBrand} from '../../services/utils';
import AgentBanner from '../AgentBanner/index';
import {getAttributionPartner, getCampaignChannel} from '../../redux/session/selectors';
import {useSelector} from 'react-redux';
import {HazardNotification} from '@nib/form-fields';
import {hspConfigurationUrl} from '../../constants';

enableES5();

const MainPageContent = styled.div`
  & main {
    background-color: ${isNibThemeBrand() ? colorSunsetPink20 : colorLightest};
    font-family: ${copyFontFamily};
  }
`;

export type PureLayoutProps = {
  pageData?: SeoProps;
  showCorporateBanner?: boolean;
};

const PureLayout: React.FC<PureLayoutProps> = ({children, pageData, showCorporateBanner = false}) => {
  const campaignChannel = useSelector(getCampaignChannel);
  const attributionPartner = useSelector(getAttributionPartner);
  const groupName = attributionPartner || campaignChannel;
  const showGenericWelcomeMessage = campaignChannel === 'NoOffer' && !attributionPartner;
  const voluntaryCorporateFunnel = isCorporateBrand();
  return (
    <PageTheme>
      <HazardNotification path={hspConfigurationUrl} />
      <MainPageContent>
        <HeaderFooter variant={getBrand() as any}>
          {showCorporateBanner && groupName && !voluntaryCorporateFunnel && <AgentBanner groupName={groupName} showGenericWelcomeMessage={showGenericWelcomeMessage} />}

          <div>
            <SEO {...pageData} />
            {children}
          </div>
        </HeaderFooter>
      </MainPageContent>
    </PageTheme>
  );
};

export default PureLayout;
